body {
	background-color: rgba(245,220,153,0.1);
}

@font-face {
	font-family: 'Calibre';
	src: url('/fonts/calibre/regular/Calibre-Regular.ttf');
	src: url('/fonts/calibre/regular/Calibre-Regular.ttf') format('truetype'),
		 url('/fonts/calibre/regular/Calibre-Regular.otf') format('opentype'),
		 url('/fonts/calibre/regular/Calibre-Regular.woff') format('woff'),
		 url('/fonts/calibre/regular/Calibre-Regular.svg#svgFontName') format('svg');
}

@font-face {
	font-family: 'Inter';
	src: url('/fonts/Inter Web/Inter-Regular.woff2') format('woff2');
}
